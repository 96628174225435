<template>
  <div v-if="sets && sets.length" class="sets">
    <h2 v-if="title">{{ title }}</h2>
    <div
      class="sets-list"
      :class="{
        'sets-list--sides': position === 'sides',
        'sets-list--left': position === 'left',
        'sets-list--right': position === 'right',
        'sets-list--more-5': sets.length > 5,
        'sets-list--equal-5': sets.length === 5,
        'sets-list--equal-4': sets.length === 4,
        'sets-list--equal-3': sets.length === 3,
        'sets-list--less-3': sets.length < 3,
      }"
    >
      <SetCardComponent
        v-for="(set, i) in sets"
        :key="set.id"
        :data="set"
        :small="!bigSets.includes(i + 1)"
        :white="set.is_white"
        :more="set.more"
        :style="{ 'grid-area': sets.length > 2 ? `a${i + 1}` : 'auto' }"
      />
    </div>
  </div>
</template>

<script>
import SetCardComponent from "components/SetCardComponent.vue";

export default {
  name: "SetsListComponent",
  props: {
    sets: Array,
    title: String,
    position: {
      type: String,
      validator(value) {
        return value === "left" || value === "sides" || value === "right";
      },
      default: "left",
    },
  },
  components: {
    SetCardComponent,
  },
  computed: {
    // позиции больших блоков набора
    bigSets() {
      if (this.sets.length > 5) {
        if (this.position === "left") {
          return [1, 2];
        } else if (this.position === "sides") {
          return [1, 6];
        } else if (this.position === "right") {
          return [5, 6];
        }
      } else if (this.sets.length === 5) {
        if (this.position === "left" || this.position === "sides") {
          return [1];
        } else if (this.position === "right") {
          return [5];
        }
      } else if (this.sets.length === 4) {
        return [1, 4];
      } else if (this.sets.length === 3) {
        return [1];
      }
      return [1, 2];
    },
  },
};
</script>

<style lang="stylus">
.sets-list {
  display: grid;
  grid-gap: var(--gap)
  +below(380px) {
    display flex
    flex-direction column
  }

  &--less-3 {
    grid-template-columns repeat(2, 1fr)
    +below(640px) {
      grid-template-columns 1fr
    }
  }

  &--equal-3 {
    grid-template-areas "a1 a2 a3"
    +below(990px) {
      grid-template-areas "a1 a1 a2" "a1 a1 a3"
    }
    +below(620px) {
      grid-template-areas "a1 a1" "a1 a1" "a2 a3"
    }
  }

  &--equal-4 {
    grid-template-areas "a1 a1 a2 a4 a4" "a1 a1 a3 a4 a4"
    +below(990px) {
      grid-template-areas "a1 a1" "a1 a1" "a2 a3" "a4 a4" "a4 a4"
    }
  }

  &--equal-5 {
    &.sets-list--left,
    &.sets-list--sides {
      grid-template-areas "a1 a1 a2 a5" "a1 a1 a3 a4"
      +below(768px) {
        grid-template-areas "a1 a1" "a1 a1" "a2 a3" "a4 a5"
      }
    }

    &.sets-list--right {
      grid-template-areas "a1 a3 a5 a5" "a2 a4 a5 a5"
      +below(768px) {
        grid-template-areas "a1 a2" "a3 a4" "a5 a5" "a5 a5"
      }
    }
  }

  &--more-5 {
    &.sets-list--sides {
      grid-template-areas "a1 a1 a2 a4 a6 a6" "a1 a1 a3 a5 a6 a6"
      +below(1100px) {
        grid-template-areas "a1 a1 a2" "a1 a1 a3" "a4 a6 a6" "a5 a6 a6"
      }
      +below(640px) {
        grid-template-areas "a1 a1" "a1 a1" "a2 a3" "a4 a5" "a6 a6" "a6 a6"
      }
    }

    &.sets-list--left {
      grid-template-areas "a1 a1 a2 a2 a3 a5" "a1 a1 a2 a2 a4 a6"
      +below(1100px) {
        grid-template-areas "a1 a1 a3" "a1 a1 a4" "a5 a2 a2" "a6 a2 a2"
      }
      +below(640px) {
        grid-template-areas "a1 a1" "a1 a1" "a2 a2" "a2 a2" "a3 a4" "a5 a6"
      }
    }

    &.sets-list--right {
      grid-template-areas "a1 a2 a5 a5 a6 a6" "a3 a4 a5 a5 a6 a6"
      +below(1100px) {
        grid-template-areas "a5 a5 a1" "a5 a5 a2" "a3 a6 a6" "a4 a6 a6"
      }
      +below(640px) {
        grid-template-areas "a1 a2" "a3 a4" "a5 a5" "a5 a5" "a6 a6" "a6 a6"
      }
    }
  }
}
</style>
