<template>
  <div class="page sets-page">
    <BreadcrumbsComponent title="Каталог всех наборов" />
    <div class="container-padding">
      <div class="page__inner">
        <SetsListComponent
          v-for="(arr, i) in setsArrays"
          :position="i % 2 === 0 ? 'left' : 'right'"
          :key="i"
          :sets="arr"
        />
        <BannersSliderComponent :items="banners" />
        <!--        <SetsListComponent :sets="sets" position="right" title="Наборы тёти Хадижат на все случаи жизни" />-->
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import BannersSliderComponent from "components/slider/BannersSliderComponent.vue";
import SetsListComponent from "components/SetsListComponent.vue";
import SETS_PAGE from "@/graphql/pages/SetsCatalogPage.graphql";

export default {
  name: "SetsPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: SETS_PAGE,
      })
      .then(({ data }) => {
        store.state.sets_page.sets = data.sets || [];
        store.state.sets_page.banners = data.banners || [];
        // дефолтные данные
        store.state.global.categories = data.categories || [];
        store.state.global.feedback = data.feedback || {};
        store.state.global.pages = data.pages || [];
      })
      .catch(() => {});
  },
  computed: {
    // массив наборов, разделенный на подмассивы
    setsArrays() {
      return this.chunkArray(this.$store.state.sets_page.sets || [], 5);
    },
    banners() {
      return this.$store.state.sets_page.banners;
    },
  },
  methods: {
    // разделение массива на подмассивы
    chunkArray(array, size) {
      let subarray = []; //массив в который будет выведен результат.
      for (let i = 0; i < Math.ceil(array.length / size); i++) {
        subarray[i] = array.slice(i * size, i * size + size);
      }
      return subarray;
    },
  },
  components: { SetsListComponent, BannersSliderComponent, BreadcrumbsComponent },
};
</script>
