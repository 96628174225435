<template>
  <router-link
    :to="route"
    class="set-card no-hover hover-image"
    :class="{
      'set-card--small': small,
      'set-card--white': white,
      'set-card--more': more,
    }"
  >
    <div class="ratio-container">
      <ImgComponent v-if="more" class="set-card__image" src="/static/images/set_catalog.jpg" />
      <ImgComponent v-else class="set-card__image" :img="data.img" width="800" height="800" />
      <div class="set-card__info">
        <span class="set-card__title" v-if="data.title">{{ data.title }}</span>
        <span v-if="data.subtitle && !small" class="set-card__description">{{ data.subtitle }}</span>
        <router-link v-if="more" :to="{ name: 'sets' }" class="btn btn--lg btn--green-hollow">
          <span>Смотреть все</span>
          <IconComponent name="arrow-small-right-1" />
        </router-link>
        <span class="set-card__tag" v-else>Набор</span>
      </div>
    </div>
  </router-link>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "SetComponent",
  components: { IconComponent },
  props: {
    data: Object,
    small: Boolean,
    white: Boolean,
    more: Boolean,
  },
  computed: {
    route() {
      return this.more ? { name: "sets" } : { name: "set", params: { id: this.data.id } };
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.set-card {
  ratio(1, 1)

  &--small &__title {
    font-size: 1.125em
    line-height: 22px;
    //font-size: calc(12px + 6 * (100vw / 1600));
    +below(1300px) {
      font-size 1em
    }
    +below(380px) {
      font-size: 1.25em
      line-height: 24px;
    }
  }

  &--white &__info {
    color var(--white)
  }

  &__image {
    border-radius var(--big-radius)
  }

  &__info {
    absolute left right top bottom
    padding 20px
    display flex
    flex-direction column
    align-items flex-start
    gap: 10px

    .btn {
      margin-top auto
    }
  }

  &__title {
    font-weight: 900;
    font-size: 2.125em
    line-height: 41px;
    text-transform: uppercase;
    max-width 300px
    word-break break-word
    +below(430px) {
      font-size: 1.5em
      line-height: 32px;
    }
  }

  &__description {
    max-width 136px
  }

  &__tag {
    margin-top auto
    padding 5px 10px
    background: var(--white-o7);
    backdrop-filter: blur(5px);
    border-radius: 100px;
    color var(--green-dark)
    font-size: 0.75em
    line-height: 14px;
  }
}
</style>
