var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.sets && _vm.sets.length)?_c('div',{staticClass:"sets"},[(_vm.title)?_c('h2',[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('div',{staticClass:"sets-list",class:{
      'sets-list--sides': _vm.position === 'sides',
      'sets-list--left': _vm.position === 'left',
      'sets-list--right': _vm.position === 'right',
      'sets-list--more-5': _vm.sets.length > 5,
      'sets-list--equal-5': _vm.sets.length === 5,
      'sets-list--equal-4': _vm.sets.length === 4,
      'sets-list--equal-3': _vm.sets.length === 3,
      'sets-list--less-3': _vm.sets.length < 3,
    }},_vm._l((_vm.sets),function(set,i){return _c('SetCardComponent',{key:set.id,style:({ 'grid-area': _vm.sets.length > 2 ? `a${i + 1}` : 'auto' }),attrs:{"data":set,"small":!_vm.bigSets.includes(i + 1),"white":set.is_white,"more":set.more}})}),1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }